import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { API_URI, SHOW_COMMEMORATE } from 'src/constants';
import { NAV_DATA } from 'src/redux/navSlice';

const Header = dynamic(import('src/components/layouts/header'), { ssr: true });
const Footer = dynamic(import('src/components/layouts/footer'));

const Layout = ({ children, sessionSevId }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [navData, setNavData] = useState(null);

  useEffect(async () => {
    const res = await fetch(`${API_URI}/api/v1.0/navigations/menu-nav?limit=9`);
    // }
    const resJson = await res.json();
    if ('msg' in resJson) {
      setNavData(null);
    } else {
      const _data = resJson.concat({
        id: 10,
        nameTh: 'CONTACT US',
        nameEng: 'contact-us',
        link: 'contact-us',
        sub: []
      });
      setNavData(_data);
      // console.log(_data);
      const find = resJson?.filter(i => i.nameEng === router?.query?.cid);
      if (!_.isEmpty(find)) {
        dispatch(NAV_DATA(find));
      }
    }
  }, []);

  return (
    <>
      <Header data={navData} sessionSevId={sessionSevId} />
      {children}
      <div style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <Footer data={navData} />
      </div>
    </>
  );
};

export default Layout;
